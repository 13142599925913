import React from 'react';
import { Link } from 'gatsby';

import logo from '@/img/dionysus-logo.png';
import nominated from '@/img/NomineeBlackBG.jpg';

import URLS from '@/data/UrlsData';

const Footer = class extends React.Component {
  render() {
    const date = new Date().getFullYear();
    return (
      <>
        <footer className='footer'>
          <div className='container'>
            <div className='footer__content cols footer__content--center'>
              <div className='footer__col'>
                <img
                  src={logo}
                  alt='Dionysus Cultural Events'
                  className='footer__logo'
                  loading='lazy'
                />
              </div>

              <div className='footer__col footer__col--grow'>
                <div className='footer__items footer__items--inline'>
                  <Link to='/' className='footer-link'>
                    Home
                  </Link>
                  <Link to='/projects' className='footer-link'>
                    Projects
                  </Link>
                  <a href={URLS.soulsDefender} className='footer-link'>
                    Soul Defender
                  </a>
                  <Link to='/people' className='footer-link'>
                    People
                  </Link>
                  {/* <Link
                    to='/news'
                    className='footer-link'>
                    News
                  </Link> */}
                  <Link to='/contact' className='footer-link'>
                    Contact
                  </Link>
                </div>
              </div>
            </div>
            <div className='footer-nomination'>
              <img
                src={nominated}
                alt='Australian Event Awards Nominee 2024'
                className='footer__nominated'
                loading='lazy'
                height='45'
              />
            </div>
          </div>
        </footer>
        <div className='container'>
          <div className='cols footer__sub mb-24 cols--center'>
            <div className='text-sm col-1'>
              &copy; {date} All rights reserved Dionysus.{' '}
            </div>
            <div className='text-center text-sm ml-a mr-a col-1'>
              <Link to='/privacy-policy' className='link'>
                Privacy Policy
              </Link>
            </div>
            <div className='text-right footer__copyright text-sm col-1'>
              Designed and developed by{' '}
              <a
                href='https://cleancommit.io'
                target='_blank'
                rel='noreferrer'
                className='link'>
                Clean Commit
              </a>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default Footer;
